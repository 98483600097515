<template>
  <header
    v-if="hasNavbar"
    class="border-b z-20"
    :class="{
      'sticky top-0 bg-white/80 backdrop-blur-md z-20':stickyNavbar,
      'bg-white':!stickyNavbar
    }"
  >
    <nav class="max-w-screen-xl px-4 2xl:px-0 mx-auto flex flex-wrap items-center justify-between py-3">
      <NuxtLink
        :to="{ name: user ? 'home' : 'index' }"
        class="flex-shrink-0 font-semibold flex items-center"
      >
        <img
          src="/img/logo.svg"
          alt="NoteForms logo"
          class="w-8 h-8"
        >
        <span
          class="ml-3 text-xl hidden sm:inline text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-blue-600"
        >NoteForms</span>
      </NuxtLink>
      <workspace-dropdown
        v-if="showAuth"
        class="ml-6"
      />
      <div class="flex md:hidden gap-4">
        <v-button
          v-track.nav_create_form_click
          class="inline-block group"
          :to="{ name: 'register' }"
        >
          Create Form
        </v-button>
        <button
          id="hamburger"
          @click="showHamburgerMenu=!showHamburgerMenu"
        >
          <span v-if="!showHamburgerMenu">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25"
              />
            </svg>
          </span>
          <span v-if="showHamburgerMenu">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </span>
        </button>
      </div>
      <div
        class="md:flex basis-auto grow items-center h-screen md:h-auto absolute md:static left-0 top-0 w-full md:w-auto mt-[63px] md:mt-0 px-4 md:pl-0 lg:pl-28 md:pr-0 transition-colors"
        :class="{'hidden':!showHamburgerMenu, 'bg-white bg-none backdrop-blur-lg': showHamburgerMenu}"
      >
        <div
          v-if="showAuth"
          class="w-full md:w-auto flex flex-col gap-y-1 md:gap-y-0 pt-2 md:pt-0 md:flex-row md:space-x-8 mx-auto"
        >
          <NuxtLink
            v-if="$route.name && !$route.name.includes('form-templates') && (user===null || userOnboarded)"
            v-track.nav_templates_click
            :to="{ name: 'form-templates' }"
            :class="navLinkClasses"
            @click="closeHamburgerMenu"
          >
            Templates
          </NuxtLink>
          <NuxtLink
            v-if="(user===null && $route.name !== 'database-views') || (user!==null && $route.name === 'index')"
            v-track.nav_db_views_click
            :to="{ name: 'database-views' }"
            :class="navLinkClasses"
            @click="closeHamburgerMenu"
          >
            Database Views
          </NuxtLink>
          <NuxtLink
            v-if="(user===null || (user && workspace && !workspace.is_enterprise)) && $route.name !== 'pricing'"
            v-track.nav_pricing_click
            :to="{ name: 'pricing' }"
            :class="navLinkClasses"
            @click="closeHamburgerMenu"
          >
            <span v-if="user && workspace && workspace.is_pro && !workspace.is_enterprise">Upgrade</span>
            <span v-else>Pricing</span>
          </NuxtLink>
          <NuxtLink
            v-if="user"
            :href="helpUrl"
            :class="navLinkClasses"
            target="_blank"
            @click="closeHamburgerMenu"
          >
            Help
          </NuxtLink>

          <div
            v-if="user"
            class="md:hidden flex flex-col gap-y-1"
          >
            <NuxtLink
              v-if="userOnboarded"
              :to="{ name: 'home' }"
              :class="navLinkClasses"
              @click="closeHamburgerMenu"
            >
              My Forms
            </NuxtLink>

            <NuxtLink
              v-if="userOnboarded"
              :to="{ name: 'views' }"
              :class="navLinkClasses"
              @click="closeHamburgerMenu"
            >
              My DB Views
            </NuxtLink>

            <div class="-mx-1 border-b my-1 md:hidden" />

            <NuxtLink
              v-if="user && user.has_customer_id"
              :to="{ name: 'settings-billing' }"
              :class="navLinkClasses"
              @click="closeHamburgerMenu"
            >
              Billing
            </NuxtLink>

            <NuxtLink
              v-if="userOnboarded"
              :to="{ name: 'affiliates' }"
              :class="navLinkClasses"
              @click="closeHamburgerMenu"
            >
              Become our affiliate
            </NuxtLink>

            <NuxtLink
              :to="{ name: 'settings-profile' }"
              :class="navLinkClasses"
              @click="closeHamburgerMenu"
            >
              Settings
            </NuxtLink>

            <NuxtLink
              v-if="user && (user.admin || user.moderator)"
              :to="{ name: 'settings-admin' }"
              :class="navLinkClasses"
              @click="closeHamburgerMenu"
            >
              Admin
            </NuxtLink>

            <div class="-mx-1 border-b my-1 md:hidden" />

            <a
              href="#"
              :class="navLinkClasses"
              @click.prevent="logout"
            >
              Logout
            </a>
          </div>
          <div
            v-else
            class="md:hidden flex flex-col gap-y-1"
          >
            <NuxtLink
              :to="{ name: 'login' }"
              :class="navLinkClasses"
              @click="closeHamburgerMenu"
            >
              Login
            </NuxtLink>
            <NuxtLink
              :to="{ name: 'register' }"
              :class="navLinkClasses"
              @click="closeHamburgerMenu"
            >
              Register
            </NuxtLink>
          </div>
        </div>

        <div
          v-if="showAuth"
          class="hidden md:flex w-full md:w-auto md:text-right items-center md:space-x-4 space-y-4 md:space-y-0"
        >
          <dropdown
            v-if="user"
            dropdown-bg-class="bg-white dark:bg-gray-800"
          >
            <template #trigger="{toggle}">
              <button
                id="dropdown-menu-button"
                type="button"
                :class="navLinkClasses"
                class="flex items-center justify-center w-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-gray-500"
                dusk="nav-dropdown-button"
                @click.stop="toggle()"
              >
                <img
                  :src="user.photo_url"
                  class="rounded-full w-6 h-6"
                >
                <p class="ml-2 hidden sm:inline">
                  {{ user.name }}
                </p>
              </button>
            </template>

            <div class="px-1">
              <NuxtLink
                v-if="userOnboarded"
                :to="{ name: 'home' }"
                class="flex px-2 py-1 hover:no-underline rounded-md text-md text-gray-700 hover:bg-neutral-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600 items-center"
              >
                <Icon
                  name="heroicons:rectangle-stack"
                  class="w-4 h-4 mr-2"
                />
                My Forms
              </NuxtLink>

              <NuxtLink
                v-if="userOnboarded"
                :to="{ name: 'views' }"
                class="flex px-2 py-1 hover:no-underline rounded-md text-md text-gray-700 hover:bg-neutral-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600 items-center"
              >
                <Icon
                  name="heroicons:table-cells"
                  class="w-4 h-4 mr-2"
                />
                My DB Views
              </NuxtLink>

              <div class="-mx-1 border-b my-1" />

              <NuxtLink
                v-if="user && user.has_customer_id"
                :to="{ name: 'settings-billing' }"
                class="px-2 py-1 rounded-md text-md text-gray-700 hover:no-underline hover:bg-neutral-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600 flex items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-4 h-4 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                  />
                </svg>
                Billing
              </NuxtLink>

              <NuxtLink
                v-if="userOnboarded"
                :to="{ name: 'affiliates' }"
                class="hidden md:flex px-2 py-1 rounded-md text-md hover:no-underline text-gray-700 hover:bg-neutral-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600 items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
                  />
                </svg>
                Become our affiliate
              </NuxtLink>

              <NuxtLink
                :to="{ name: 'settings-profile' }"
                class="px-2 py-1 rounded-md text-md text-gray-700 hover:no-underline hover:bg-neutral-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600 flex items-center"
              >
                <svg
                  class="w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
                Settings
              </NuxtLink>

              <div
                v-if="user && (user.admin || user.moderator)"
                class="-mx-1 border-b my-1"
              />

              <NuxtLink
                v-if="user && (user.admin || user.moderator)"
                :to="{ name: 'settings-admin' }"
                class="px-2 py-1 rounded-md text-md text-gray-700 hover:no-underline hover:bg-neutral-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600 flex items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-4 h-4 mr-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z"
                  />
                </svg>

                Admin
              </NuxtLink>

              <div class="-mx-1 border-b my-1" />
              
              <a
                href="#"
                class="px-2 py-1 rounded-md text-md text-gray-700 hover:bg-neutral-100 hover:no-underline hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600 flex items-center"
                @click.prevent="logout"
              >
                <svg
                  class="w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                  />
                </svg>
                Logout
              </a>
            </div>
          </dropdown>
          <div
            v-else
            class="flex items-center space-x-6"
          >
            <NuxtLink
              v-if="$route.name !== 'login'"
              :to="{ name: 'login' }"
              :class="navLinkClasses"
            >
              Login
            </NuxtLink>
            <v-button
              v-track.nav_create_form_click
              class="inline-block group"
              :to="{ name: 'register' }"
            >
              Create Form
            </v-button>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { computed, ref } from 'vue'
import Dropdown from '~/components/global/Dropdown.vue'
import WorkspaceDropdown from './WorkspaceDropdown.vue'
import noteformsConfig from '~/noteforms.config.js'

export default {
  components: {
    WorkspaceDropdown,
    Dropdown
  },

  setup () {
    const { openHelpdesk } = useCrisp()
    const authStore = useAuthStore()
    return {
      noteformsConfig,
      authStore,
      openHelpdesk,
      appStore: useAppStore(),
      formsStore: useFormsStore(),
      viewsStore: useViewsStore(),
      workspacesStore: useWorkspacesStore(),
      user: computed(() => authStore.user),
      isIframe: useIsIframe(),
      showHamburgerMenu: ref(false)
    }
  },

  data: () => ({
    navLinkClasses: 'border border-transparent hover:border-gray-200 text-gray-500 hover:text-gray-800 hover:no-underline dark:hover:text-white py-2 px-3 hover:bg-gray-50 rounded-md text-sm font-medium transition-colors w-full md:w-auto text-center md:text-left'
  }),

  computed: {
    helpUrl() {
      return this.noteformsConfig.links.help_url
    },
    stickyNavbar () {
      return useRoute().meta?.stickyNavbar ?? false
    },
    form () {
      if (this.$route.name && this.$route.name.startsWith('forms-slug')) {
        return this.formsStore.getByKey(this.$route.params.slug)
      }
      return null
    },
    view () {
      if (this.$route.name && this.$route.name.startsWith('views-slug')) {
        return this.viewsStore.getByKey(this.$route.params.slug)
      }
      return null
    },
    workspace () {
      return this.workspacesStore.getCurrent
    },
    showAuth () {
      return this.$route.name && !(this.$route.name === 'forms-slug' || this.$route.name === 'views-slug')
    },
    hasNavbar () {
      if (this.isIframe) return false

      if (this.$route.name && this.$route.name === 'forms-slug') {
        if (this.form) {
          // If there is a cover, or if branding is hidden remove nav
          if (this.form.cover_picture || this.form.no_branding || this.isCustomDomain) {
            return false
          }
        } else {
          return false
        }
      }

      if (this.$route.name && this.$route.name === 'views-slug') {
        if (this.view) {
          // If branding is hidden remove nav
          if (this.view.no_branding || this.isCustomDomain) {
            return false
          }
        } else {
          return false
        }
      }

      return !this.appStore.navbarHidden
    },
    userOnboarded () {
      return this.user && this.user.workspaces_count > 0
    },
    isCustomDomain () {
      if (process.server || !useRuntimeConfig().public.customDomainsEnabled) {
        return false
      }
      return (new URL(window.location.href)).hostname !== (new URL(useRuntimeConfig().public.appUrl)).hostname
    }
  },

  mounted () {},

  methods: {
    async logout () {
      // Log out the user.
      this.authStore.logout()

      // Reset store
      this.workspacesStore.resetState()
      this.formsStore.resetState()
      this.viewsStore.resetState()

      // Redirect to login.
      this.closeHamburgerMenu()
      const router = useRouter()
      router.push({ name: 'login' })
    },
    closeHamburgerMenu () {
      this.showHamburgerMenu = false
    }
  }
}
</script>
