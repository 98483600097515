<template>
  <div class="main-layout min-h-screen flex flex-col">
    <Navbar />

    <div
      v-if="alert && alert.type"
      class="w-full md:w-4/5 lg:w-3/5 md:mx-auto md:max-w-4xl px-4"
    >
      <Alert
        v-if="alert.type"
        class="my-4"
        :message="alert.message"
        :type="alert.type"
        :auto-close="alert.autoClose"
        :confirmation-proceed="alert.confirmationProceed"
        :confirmation-cancel="alert.confirmationCancel"
        @close="closeAlert"
      />
    </div>

    <div
      v-if="hasSideBar"
      class="md:flex flex-grow"
    >
      <Sidebar />
      <div class="flex-grow">
        <slot />
      </div>
    </div>
    <slot
      v-else
      class="flex-grow"
    />

    <notion-forms-footer
      v-if="hasFooter"
      class="border-t md:hidden"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import Alert from '~/components/global/Alert.vue'

const route = useRoute()

const alert = computed(() => {
  // TODO: check later
  return false // this.$root.alert
})
const hasSideBar = computed(() => {
  return route.meta.hasSidebar === true
})
const hasFooter = computed(() => {
  return route.meta.hasFooter === true
})

function closeAlert () {
  this.$root.alert = {
    type: null,
    autoClose: 0,
    message: '',
    confirmationProceed: null,
    confirmationCancel: null
  }
}
</script>
